@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&subset=latin,latin-ext');
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto';
}

.leaflet-container {
  height: 500px;
  width: 100%;
  margin: 0 auto;
}

img {
  max-width: 100%;
}

iframe {
  height: 100% !important;
}
